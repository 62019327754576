import {
	Box,
	Grid,
	Table,
	TableBody,
	TableCell, TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
} from '@mui/material';
import React from 'react';
import { IPreviewTable } from '../common/interface';
import { useTranslation } from 'react-i18next';


export default function PreviewTable(
	props: {
		previewTable: IPreviewTable;
	}
) {

	const { t } = useTranslation();
	const [page, setPage] = React.useState<number>(0);
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);

	const renderPreviewTable = () => {
		return (
			<>
				{props.previewTable &&
					<TableContainer>
						<Table size="small">
							<TableHead>
								<TableRow key={'preview-header'}>
									{props.previewTable.columns.map(column => (
										<TableCell>{column}</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{(rowsPerPage > 0
										? props.previewTable.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										: props.previewTable.rows
								)?.map(row => {
									return (
										<TableRow
											key={row.id}
										>
											{props.previewTable.columns.map(column => (
												<TableCell>{row[column]}</TableCell>
											))}
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
						<TablePagination
							sx={{
								display: 'flex',
								justifyContent: 'center',
							}}
							rowsPerPageOptions={[10, 25, 50]}
							component="div"
							count={props.previewTable.rows.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={(e, pageNumber) => setPage(pageNumber)}
							onRowsPerPageChange={(event) => {
								setPage(0);
								setRowsPerPage(parseInt(event.target.value, 10));
							}}
						/>
					</TableContainer>

				}
			</>

		);
	}
	return (
		<Box sx={{ padding: 2 }}>
			<Grid container direction={'column'} justifyContent={'flex-start'}>
				<Grid item sx={{ display: 'flex', flex: 1}}>
					<Typography variant={'h6'}>{t('preview.preview')}</Typography>
				</Grid>
				<Grid item sx={{ display: 'flex', flex: 1}}>
					<Typography variant={'caption'}>{t('preview.previewSize')}{props.previewTable?.size}</Typography>
				</Grid>
			</Grid>
			{renderPreviewTable()}
		</Box>
	);
}
