import { EColumnTypes, ECondition } from './enums';
import { environment } from '../environments/environment';

export const getConstrainByDimension = (dimension: string) => {
	const base = [ ECondition.EQUALS, ECondition.NOT_EQUAL ];
	switch (dimension) {
		case EColumnTypes.STRING:
			return base.concat([ECondition.CONTAINS, ECondition.REGEXP]);
		case EColumnTypes.NUMBER:
			return base.concat([ECondition.MORE_THAN, ECondition.MORE_OR_EQUAL, ECondition.LESS_THAN, ECondition.LESS_OR_EQUAL]);
		case EColumnTypes.BOOLEAN:
			return base;
		default:
			return [];
	}

};

export const adminAccess = () => {
	try {
		const query = window.location.search.split('?')[1].split('=');
		if (!environment.queryKey || !environment.queryValue) {
			console.log('Missing query key and/or value');
		}
		if (query[0] === environment.queryKey && query[1] === environment.queryValue) {
			return false;
		}
	} catch (error) {
	}
	console.log('Query is not compatible, admin access is not granted.');
	return true;
}
