
export enum EOperators {
	AND = 'AND',
	OR = 'OR',
}

export enum EColumnTypes {
	NUMBER = 'number',
	STRING = 'string',
	BOOLEAN = 'boolean'
}

export enum EConstrainType {
	INCLUDE = 'include',
	EXCLUDE = 'exclude'
}

export enum EDaysOfWeek {
	MONDAY = 'MONDAY',
	TUESDAY = 'TUESDAY',
	WEDNESDAY = 'WEDNESDAY',
	THURSDAY = 'THURSDAY',
	FRIDAYS = 'FRIDAYS',
	SATURDAY = 'SATURDAY',
	SUNDAY = 'SUNDAY',
}

export enum ECondition {
	EQUALS = 'EQUALS',
	NOT_EQUAL = 'NOT_EQUAL',
	CONTAINS = 'CONTAINS',
	REGEXP = 'REGEXP',
	MORE_THAN = '>',
	LESS_THAN = '<',
	MORE_OR_EQUAL = '>=',
	LESS_OR_EQUAL = '<=',
}

export enum EFrequency {
	WEEKLY = 'WEEKLY',
	BIWEEKLY = 'BIWEEKLY',
	MONTHLY = 'MONTHLY'
}
