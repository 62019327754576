import { ArrowBackIosNew, CalendarToday, FileDownload, Save } from '@mui/icons-material';
import {
    Alert,
    AlertColor,
    AppBar,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    LinearProgress,
    MenuItem,
    Paper,
    Select,
    Snackbar,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Toolbar,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import React, { useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { ConstrainTable, PreviewTable } from './index';
import { IConstrains, IDimension, IDimensionOption, ISegmentationItem, ISegmentConstrain } from '../common/interface';
import { EConstrainType, EDaysOfWeek, EFrequency } from '../common/enums';
import FormActionButtons from './form-action-button';
import { useTranslation } from 'react-i18next';
import { useGetSegmentsPreview } from '../services/segments';
import * as lodash from 'lodash';
import { adminAccess } from '../common/utils';


const csvInstance = React.createRef<any | null>();

export default function SegmentDetails(
    props: {
        usersTotal?: number;
        dimensions: Array<IDimension>;
        exports: Array<string>;
        onModeChange: (mode: 'summary' | 'detail') => void;
        handleUpdate: (segment: Partial<ISegmentationItem>) => void;
        handleCreate: (segment: Partial<ISegmentationItem>) => void;
        columnOptions: Array<IDimensionOption>;
        segment?: ISegmentationItem | null;
    })  {

    const { t } = useTranslation();
    const theme = useTheme();
    const [segmentId, setSegmentId] = React.useState<string>('');
    const [title, setTitle] = React.useState<string | null>(null);
    const [includeConstrain, setIncludeConstrain] = React.useState<Array<ISegmentConstrain>>([]);
    const [excludeConstrain, setExcludeConstrain] = React.useState<Array<ISegmentConstrain>>([]);
    const [description, setDescription] = React.useState<string | undefined>( undefined);
    const [exports, setExports] = React.useState<Array<string>>([]);
    const [exportDays, setExportDays] = React.useState<Array<EDaysOfWeek>>([]);
    const [frequency, setFrequency] = React.useState<EFrequency | undefined>(props.segment?.frequency);
    const [showDescription, setShowDescription] = React.useState<boolean>(false);
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
    const [snackState, setSnackState] = React.useState<AlertColor>('success');
    const [snackMessage, setSnackMessage] = React.useState<string | null>(null);
    const [openDialog, setOpenDialog] = React.useState<boolean>(false);
    const [executing, setExecuting] = React.useState<boolean>(false);
    const [showPreview, setShowPreview] = React.useState<boolean>(false);
    const [staticSegment, setStaticSegment] = React.useState<boolean>(false);

    const [constrains, setConstrains] = React.useState<IConstrains | null>(null);
    const [recount, setRecount] = React.useState<boolean>(false);


    const { loading: loadingPreview, data: previewTable, error: errorPreview } = useGetSegmentsPreview(constrains);

    useEffect(() => {
        if (props.segment) {
            setPropsToState();
        }
    }, []);

    useEffect(() => {
        if (recount) {
            recountPreviewSegmentWithValidInput();
            setRecount(false);
        }
    }, [includeConstrain, excludeConstrain]);

    useEffect(() => {
        setPropsToState();
    }, [props.segment?.segmentId]);

    useEffect(() => {
        setExecuting(loadingPreview);
    }, [loadingPreview]);

    useEffect(() => {
        if (!loadingPreview && errorPreview) {
            setOpenSnackbar(true);
            setSnackState('error');
            setSnackMessage(t('userMessages.previewFail'));
        }
    }, [loadingPreview]);

    const renderToolBar = () => {
        return (
            <AppBar position='sticky'>
                <Toolbar
                    sx={{ background: theme.palette.secondary.main }}
                >
                    <Grid container justifyContent={'flex-end'} alignItems={'center'}>
                        <Grid item sx={{ flex: 1, display: 'flex' }}>
                            <Button startIcon={<ArrowBackIosNew />} variant={'text'} onClick={async () => await handleJumpToOverview()}>
                                {t('button.back')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant={'text'} onClick={async () => await handleJumpToOverview()}>
                                <Typography variant='body2' color='inherit' component='div' sx={{ mx: '15px' }}>
                                    {t('button.closeWithoutChanges')}
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                startIcon={<Save/>} variant={'contained'}
                                onClick={() => segmentId ? handleSave() : handleCreate()}
                                disabled={adminAccess()}
                            >
                                {t(segmentId ? 'button.segment_save' : 'button.segment_create')}
                            </Button>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        );
    };

    const renderSideCards = () => {
        return (
            <Grid container item xs={3} sx={{ marginY: 3 }} direction={'column'}>
                <Grid item sx={{ marginY: 1 }}>
                    <Paper sx={{ padding: 2 }}>
                        <Typography variant={'body1'} align={'left'} sx={{ fontWeight: 'bold' }}>{t('summary.yourCompleteDataset')}</Typography>
                        <Typography variant={'body2'} align={'left'}>{t('summary.usersTotal')}</Typography>
                        <Typography variant={'body1'} align={'left'} sx={{ fontWeight: 'bold' }}>{props.usersTotal}</Typography>
                        <Divider sx={{ my: 1 }} />
                        <Typography variant={'body1'} align={'left'} sx={{ fontWeight: 'bold' }}>{t('summary.filteredDataset')}</Typography>
                        <Typography variant={'body2'} align={'left'}>{t('summary.occurrences')}</Typography>
                        <Typography variant={'body1'} align={'left'} sx={{ fontWeight: 'bold' }}>{previewTable?.size || props.segment?.users_count || props.usersTotal}</Typography>
                    </Paper>
                </Grid>
                <Grid item sx={{ marginY: 1 }}>
                    {!!props.exports?.length && renderExports()}
                </Grid>
                <Grid container item sx={{ marginY: 1 }} spacing={3}>
                    <Grid item>
                        <Button variant={'outlined'} onClick={() => showSegmentPreview()}>
                            {t('button.showPreview')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Tooltip title={t('button.previewDownloadHelper')}>
                            <Button variant={'outlined'} onClick={() => getPreviewRows()} startIcon={<FileDownload />}>
                                {t('button.previewDownload')}
                            </Button>
                        </Tooltip>
                        {previewTable &&
                            <CSVLink
                                data={previewTable.rows}
                                filename={'segment_preview.csv'}
                                asyncOnClick={true}
                                ref={csvInstance}
                            />}
                    </Grid>
                    <Grid item>
                        <Tooltip title={t('button.datasetDownloadHelper')}>
                            <Button variant={'outlined'} startIcon={<FileDownload />}>
                                {t('button.datasetDownload')}
                            </Button>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    const renderExports = () => {
        return (
            <Paper sx={{ padding: 2 }}>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 6, sm: 8, md: 12 }} sx={{display: 'flex'}} justifyContent={'flex-start'}>
                {props.exports.map((item, index) => {
                    return (
                        <Grid item xs={12} md={6} key={index} sx={{display: 'flex'}} justifyContent={'flex-start'} alignItems={'center'}>
                            <Grid item>
                                <FormControlLabel
                                    key={`${item}-checkbox`}
                                    control={
                                        <Checkbox
                                            checked={exports?.includes(item)}
                                            onChange={(event, checked) => handleExportChange(item, checked)}
                                        />
                                    }
                                    label={item}
                                />
                            </Grid>
                            <Grid item>
                                {
                                    <img src={`${item.split(' ')[0].toLowerCase()}.png`} width={30} height={30} alt='logo' />
                                }
                            </Grid>

                        </Grid>
                    );
                })}
                </Grid>
                <Divider sx={{ my: 1 }} />
                {renderExportDays()}
                <Divider sx={{ my: 1 }} />
                {renderExportFrequency()}
            </Paper>
        );
    }

    const renderExportDays = () => {
        return (
            <Grid container direction={'row'} justifyContent={'center'}>
            <ToggleButtonGroup
                sx={{
                    padding: theme.spacing(0, 1)
                }}
                size='small'
                value={exportDays}
                onChange={(event, value) => {
                    setExportDays( typeof value === 'string' ? [value] : value);
                }}
                disabled={!Boolean(exports?.length)}
            >
                {Object.keys(EDaysOfWeek).map(item => {
                    return (
                        <ToggleButton
                            sx={{
                                margin: theme.spacing(1),
                                padding: theme.spacing(0, 1),
                                color: theme.palette.primary.main,
                                '&.MuiToggleButtonGroup-grouped': {
                                    '&:not(:first-child)': {
                                        border: '1px solid',
                                        borderRadius: '50%'
                                    },
                                    '&:first-child': {
                                        border: '1px solid',
                                        borderRadius: '50%'
                                    },
                                    '&:hover':{
                                        background: theme.palette.secondary.dark,
                                    }
                                },
                                '&.Mui-selected': {
                                    color: theme.palette.getContrastText(theme.palette.secondary.main),
                                    background: theme.palette.secondary.main,
                                    border: 'none !important',
                                },
                                minWidth: 32,
                                maxWidth: 32,
                                height: 32,
                                textTransform: 'unset',
                                fontSize: '0.75rem'
                            }}
                            key={item}
                            value={exports?.length ? item : {}}
                        >
                            {t(`daysOfTheWeek.${item}`)}
                        </ToggleButton>
                    );
                })}
            </ToggleButtonGroup>
            </Grid>
        );
    }

    const renderExportFrequency = () => {
        return (
            <Grid container direction={'row'} justifyContent={'center'}>
                <Select
                    id={'condition-select'}
                    sx={{ minWidth: 250 }}
                    value={frequency ? t(`frequencies.${frequency}`): frequency}
                    defaultValue={''}
                    label={t('constrainDetail.frequency')}
                    displayEmpty
                    disabled={!Boolean(exportDays?.length)}
                    size={'small'}
                    renderValue={(selected) => {
                        if (!selected && !frequency) {
                            return t('constrainDetail.frequency');
                        }
                        return selected ? selected : t(`frequencies.${frequency}`);
                    }}
                    onChange={(event) => handleExportFrequencyChange(event.target.value)}
                >
                    {Object.values(EFrequency).map((item, index) => {
                        return (
                            <MenuItem value={item} key={`${item}-${index}`}>{t(`frequencies.${item}`)}</MenuItem>
                        );
                    })}
                </Select>
            </Grid>
        );
    }

    const handleExportFrequencyChange = (selected: string) => {
        const frequency: EFrequency = EFrequency[selected as keyof typeof EFrequency];
        setFrequency(frequency);
    }

    const handleExportChange = (exportName: string, checked: boolean) => {
        const newExports = exports.map(item => item);
        if (!newExports.length) {
            setExportDays([]);
        }
        if (checked) {
            newExports.push(exportName);
        } else {
            const index = newExports.findIndex(item => item === exportName);
            newExports.splice(index, 1);
        }
        setExports(newExports);
    }

    const getPreviewRows = () => {
        recountPreviewSegmentWithValidInput();
        csvInstance.current.link.click();
    };

    const checkInput = (preview?: boolean): boolean => {
        if ((preview || title) && constrainsAreValid(includeConstrain) && constrainsAreValid(excludeConstrain)) {
            return true;
        }
        setOpenSnackbar(true);
        setSnackState('error');
        setSnackMessage(t('userMessages.segmentIncomplete'));
        return false;
    };

    const checkConstrainsLength = (): boolean => {
        if (!includeConstrain && !excludeConstrain) {
            return false;
        }
        if (includeConstrain) {
            const include = includeConstrain?.filter(item => Boolean(item.dimension));
            return Boolean(include.length);
        }

        const exclude = excludeConstrain?.filter(item => Boolean(item.dimension));

        return Boolean(exclude.length);
    };

    const constrainsAreValid = (constrains: Array<Partial<ISegmentConstrain>>): boolean => {
        return !constrains?.some(item => {
            if (!item.value || !item.condition || !item.dimension) {
                return true;
            }
        });
    };

    const handleSave = () => {
        setOpenDialog(false);
        if (checkInput() && title) {
            props.handleUpdate({
                segmentId,
                title: title,
                description: description || '',
                exports: exports,
                exportDays: exportDays,
                static: staticSegment,
                users_count: props.segment?.users_count,
                frequency: frequency,
                constrains: {
                    include: includeConstrain,
                    exclude: excludeConstrain,
                }
            });
        }

    }

    const deepClone = (value: any) => {
        return lodash.cloneDeep(value);
    }

    const handleCreate = async () => {
        if (!checkConstrainsLength()) {
            setOpenDialog(true);
            return;
        }
        if (!checkInput()) {
            return;
        }
        await createSegment();
    }

    const handleJumpToOverview = async (): Promise<void> => {
        setSegmentId('');
        setTitle(null);
        setIncludeConstrain([]);
        setExcludeConstrain([]);
        setDescription(undefined);
        await props.onModeChange('summary');
    };

    const showSegmentPreview = () => {
        recountPreviewSegmentWithValidInput();
        setShowPreview(true);
    };

    const getPreview = () => {
        setConstrains({ include: includeConstrain, exclude: excludeConstrain });
    };

    const recountPreviewSegmentWithValidInput = () => {
        if (!checkInput(true)) {
            return;
        }
        getPreview();
    }

    const updateIncludeConstrains = (newConstrains: Array<ISegmentConstrain>, recount?: boolean) => {
        setIncludeConstrain(deepClone(newConstrains));
        if (recount) {
            setRecount(recount);
        }
    };


    const updateExcludeConstrains = (newConstrains: Array<ISegmentConstrain>, recount?: boolean) => {
        setExcludeConstrain(deepClone(newConstrains));
        if (recount) {
            setRecount(recount);
        }
    };

    const createSegment = async () => {
        setOpenDialog(false);
        if (title) {
            props.handleCreate({
                title: title,
                description: description,
                exports: exports,
                exportDays: exportDays,
                static: staticSegment,
                frequency: frequency,
                constrains: {
                    include: includeConstrain,
                    exclude: excludeConstrain,
                }
            });
        }
    }

    const setPropsToState = () => {
        if (props.segment) {
            const segment = props.segment;
            setSegmentId(segment.segmentId);
            setTitle(segment.title);
            setIncludeConstrain(segment.constrains?.include || []);
            setExcludeConstrain(segment.constrains?.exclude || []);
            setDescription(segment.description);
            setExports(segment.exports || []);
            setExportDays(segment.exportDays?.map(day => day as EDaysOfWeek));
            setStaticSegment(segment.static);
        }
    }

    return (
        <div style={{ overflowX: 'hidden' }}>
            {renderToolBar()}
            {
                executing && <LinearProgress />
            }
            <Grid container spacing={1.75} sx={{ margin: 1, padding: 1 }} >
                <Grid item xs={8.75} sx={{ marginY: 3 }} >
                    <Grid item container xs={9} direction={'column'} justifyContent={'flex-start'} sx={{ marginY: 3 }}>
                        <Grid item sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <FormControl>
                                <TextField
                                    size={'small'}
                                    label={t('segment.segmentName')}
                                    required
                                    error={!title}
                                    onChange={({ target }) => {
                                        setTitle(target.value);
                                    }}
                                    value={title || ''}
                                />
                            </FormControl>

                        </Grid>
                        <Grid item sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <FormControlLabel
                                label={t('segment.staticSegment')}
                                control={<Checkbox
                                    checked={staticSegment}
                                    disabled={Boolean(segmentId)}
                                    onChange={({ target }) => {
                                        setStaticSegment(target.checked);
                                    }}
                                />}
                            />
                        </Grid>
                        <Grid item sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                            {description || showDescription
                                ?
                                <FormControl fullWidth sx={{ p: 1 }}>
                                    <TextField
                                        label={t('segment.description')}
                                        multiline
                                        maxRows={3}
                                        sx={{ width: '99%' }}
                                        value={description || ''}
                                        onChange={(event) => {
                                            setDescription(event.target.value);
                                        }}
                                    />
                                </FormControl>
                                : <Button
                                    sx={{ padding: '3px' }}
                                    startIcon={<CalendarToday />}
                                    variant={'text'}
                                    onClick={() => {
                                        setShowDescription(true);
                                    }}
                                >
                                    {t('button.addDescription')}
                                </Button>
                            }
                        </Grid>
                    </Grid>

                    <Grid item sx={{ my: 3 }}>
                        <ConstrainTable
                            key={'include-constrains'}
                            constrainType={EConstrainType.INCLUDE}
                            constrains={includeConstrain}
                            dimensions={props.dimensions}
                            segmentId={segmentId}
                            updateConstrains={updateIncludeConstrains}
                            columnOptions={props.columnOptions}
                        />
                    </Grid>

                    <Grid item sx={{ my: 3 }}>
                        <ConstrainTable
                            key={'exclude-constrains'}
                            constrainType={EConstrainType.EXCLUDE}
                            constrains={excludeConstrain}
                            dimensions={props.dimensions}
                            segmentId={segmentId}
                            updateConstrains={updateExcludeConstrains}
                            columnOptions={props.columnOptions}
                        />
                    </Grid>
                </Grid>
                {renderSideCards()}
            </Grid>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={openSnackbar}
                onClose={() => {
                    setOpenSnackbar(false);
                }}
                autoHideDuration={6000}
            >
                <Alert severity={snackState}>
                    {snackMessage}
                </Alert>
            </Snackbar>
            <Dialog open={openDialog} onClose={() => {
                setOpenSnackbar(false);
            }}>
                <DialogTitle>
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('userMessages.createEmptySegmentWarning')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ margin: 10 }}>
                    <FormActionButtons
                        onConfirm={() => createSegment()}
                        onClose={() => {
                            setOpenSnackbar(false);
                        }}
                        confirmText={t('button.confirm')}
                    />
                </DialogActions>
            </Dialog>
            {
                showPreview && previewTable &&
                <PreviewTable previewTable={previewTable} />
            }
        </div>
    );
}
