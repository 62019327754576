import { AddCircle, Cancel } from '@mui/icons-material';
import {
    Alert,
    AlertColor,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Paper, Snackbar, Switch,
    Table,
    TableBody,
    TableCell,
    TableHead, TablePagination,
    TableRow, Tooltip,
    Typography, useTheme,
} from '@mui/material';
import React, { BaseSyntheticEvent } from 'react';
import { ISegmentationItem, ISegmentationOverview } from '../common/interface';
import FormActionButtons from './form-action-button';
import { useTranslation } from 'react-i18next';
import { adminAccess } from '../common/utils';


const SEGMENTS_COUNT = 3;

export default function SegmentationSummary(props: {
    overview: ISegmentationOverview;
    onModeChange: (mode: 'summary' | 'detail') => void;
    onRowClick: Function;
    handleRemoveRow: Function;
}) {

    const { t } = useTranslation();
    const theme = useTheme();
    const [openDialog, setOpenDialog] = React.useState<boolean>(false);
    const [segmentName, setSegmentName] = React.useState<string>('');
    const [currentSegmentId, setCurrentSegmentId] = React.useState<string>('');
    const [staticSegment, setStaticSegment] = React.useState<boolean>(false);
    const [page, setPage] = React.useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(25);
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
    const [snackState, setSnackState] = React.useState<AlertColor>('success');
    const [snackMessage, setSnackMessage] = React.useState<string | null>(null);

    const renderSegmentsTable = (segments: Array<ISegmentationItem>) => {
        return (
            <Paper sx={{
                padding: 2,
                margin: 1,
                backgroundColor: theme.palette.grey['500'],
            }}>
                <Grid container justifyContent={'space-between'}>
                    <Grid item>
                        <Typography variant={'subtitle2'} sx={{ padding: 1, color: '#ffffff', fontWeight: 'bold' }}>{t('overview.yourSegments')}</Typography>
                    </Grid>
                    <Grid item>
                        <Button startIcon={<AddCircle />} onClick={async () => props.onModeChange('detail')}>
                            {t('button.addSegment')}
                        </Button>
                    </Grid>
                </Grid>
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('summary.segments')}</TableCell>
                                <TableCell align='left' sx={{ fontWeight: 'bold' }}>{t('summary.occurrences')}</TableCell>
                                <TableCell align='left' sx={{ fontWeight: 'bold' }}>{t('summary.exportingIn')}</TableCell>
                                <TableCell align='left' sx={{ fontWeight: 'bold' }}>{t('summary.lastExport')}</TableCell>
                                <TableCell align='left' sx={{ fontWeight: 'bold' }}>{t('summary.active')}</TableCell>
                                <TableCell align='center'></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                (rowsPerPage > 0
                                        ? segments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : segments
                                ).map((segment: ISegmentationItem) => {
                                    const exports = segment.exports?.slice(0, SEGMENTS_COUNT);
                                    const diff = segment.exports && exports && segment.exports.length - exports.length;
                                    return (
                                        <TableRow
                                            key={segment.segmentId}
                                            sx={{
                                                "&:hover": {
                                                    cursor: 'pointer'
                                                }
                                            }}
                                            onClick={() => props.onRowClick(segment)}
                                        >
                                            <TableCell component='th' scope='row'>
                                                {segment.title}
                                            </TableCell>
                                            <TableCell align='left'>{segment.users_count} / {props.overview.summary.total_customers}</TableCell>
                                            <TableCell align='left'>
                                                <Tooltip title={segment.exports?.join(', ')}>
                                                    <Grid container>
                                                        <Grid item> {exports?.join(', ')} </Grid>
                                                        {
                                                            !!diff &&
                                                            <Grid item>
                                                                <Typography variant='body1' ml={1}>...</Typography>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell align='left'>
                                                {segment?.lastExport || ''}
                                            </TableCell>
                                            <TableCell align='left'>
                                                <Switch
                                                    defaultChecked
                                                    onClick={(event: BaseSyntheticEvent) => {
                                                        event.stopPropagation();
                                                        if (event.target.checked) {
                                                            setSnackMessage(t('userMessages.exportActive'));
                                                        } else {
                                                            setSnackMessage(t('userMessages.exportInactive'));
                                                        }
                                                        setOpenSnackbar(true);
                                                        setSnackState('info');
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align='center'>
                                                <IconButton
                                                    disabled={adminAccess()}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        handleRemoveSegment(segment);
                                                    }}
                                                >
                                                    <Cancel />
                                                </IconButton>
                                            </TableCell>

                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                    <TablePagination
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                        rowsPerPageOptions={[10, 25, 50]}
                        component='div'
                        count={segments?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(e, pageNumber) => setPage(pageNumber)}
                        onRowsPerPageChange={(event) => {
                            setPage(0);
                            setRowsPerPage(parseInt(event.target.value, 10));
                        }}
                    />
                </Paper>
            </Paper>
        );
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const handleRemoveSegment = (segment: ISegmentationItem) => {
        setOpenDialog(true);
        setCurrentSegmentId(segment.segmentId);
        setStaticSegment(Boolean(segment.static));
        setSegmentName(segment.title);
    };

    return (
        <div
            style={{ padding: '70px 50px' }}
        >
            <Typography variant={'h5'} style={{ margin: 10 }}>{t('overview.segmentationOverview')}</Typography>
            {renderSegmentsTable(props.overview.segments)}
            <Dialog open={openDialog} onClose={handleDialogClose} maxWidth='md' fullWidth>
                <DialogTitle>
                    {segmentName}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('userMessages.deleteSegmentConfirm')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ margin: 10 }}>
                    <FormActionButtons
                        onConfirm={() => {
                            props.handleRemoveRow(currentSegmentId, staticSegment);
                            handleDialogClose();
                        }}
                        onClose={() => handleDialogClose()}
                        confirmText={t('button.confirm')}
                        closeText={t('button.cancel')}
                    />
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={openSnackbar}
                onClose={() => {
                    setOpenSnackbar(false);
                }}
                autoHideDuration={6000}
            >
                <Alert severity={snackState}>
                    {snackMessage}
                </Alert>
            </Snackbar>
        </div>
    );


}
