import { Button, Grid, Link, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function FormActionButtons (
	props: {
		onConfirm: Function;
		onClose: Function;
		clearText?: string;
		confirmText?: string;
		closeText?: string;
	}) {
	const { t } = useTranslation();
	return (
		<Grid container direction="row" alignItems="center" spacing={2}>
			<Grid item>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => props.onConfirm()}
				>
					{props.confirmText ? props.confirmText : t('button.save')}
				</Button>
			</Grid>
			<Grid item>
				<Link
					onClick={() => props.onClose()}
				>
					<Typography variant="body1" >
						{ props.closeText ?? t('button.closeWithoutChanges')}
					</Typography>
				</Link>
			</Grid>
		</Grid>
	);
}
