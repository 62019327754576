import React from 'react';
import AxiosClient from './axiosClient';
import { IConstrains, IPreviewTable, ISegmentationItem, ISegmentationOverview } from '../common/interface';

const urlPrefix = '/api/segments';


export const useGetSegmentsOverview = (reload: boolean) => {
    const [data, setData] = React.useState<ISegmentationOverview | null>(null);
    const [error, setError] = React.useState<Error | null>(null);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (reload) {
            setLoading(true);
            (async () => {
                await AxiosClient.get(urlPrefix, setData, setError, setLoading);
            })();
            return () => {
            };
        }
    }, [reload]);


    return { loading, data, error };

};

export const useGetSegmentsPreview = (constrains: IConstrains | null) => {
    const [data, setData] = React.useState<IPreviewTable | null>(null);
    const [error, setError] = React.useState<Error | null>(null);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (constrains) {
            setLoading(true);
            setError(null);
            (async () => {
                await AxiosClient.get(`${urlPrefix}/preview`, setData, setError, setLoading, { params: { ...constrains } });
            })();
        }

        return () => {
        };
    }, [constrains]);


    return { loading, data, error };

};

export const useCreateSegment = (segment: Partial<ISegmentationItem> | null) => {
    const [data, setData] = React.useState([]);
    const [error, setError] = React.useState<Error | null>(null);
    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (segment) {
            setLoading(true);
            (async () => {
                await AxiosClient.post(urlPrefix, setData, setError, setLoading, undefined, { ...segment });
            })();
        }
        return () => {
        };
    }, [segment]);


    return { loading, data, error };

};


export const useUpdateSegments = (segment: Partial<ISegmentationItem> | null, changed: boolean) => {
    const [error, setError] = React.useState<Error | null>(null);
    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (segment && changed) {
            setLoading(true);
            (async () => {
                await AxiosClient.put(`${urlPrefix}/${segment.segmentId}`, setError, setLoading, undefined, undefined, { ...segment });
            })();
        }
        return () => {
        };
    }, [segment]);


    return { loading, error };

};

export const useDeleteSegment = (segmentId: string | null | undefined, staticSegment: boolean | null) => {
    const [error, setError] = React.useState<Error | null>(null);
    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (segmentId) {
            setLoading(true);
            (async () => {
                await AxiosClient.delete(`${urlPrefix}/${segmentId}`, setError, setLoading, undefined, { data: { staticSegment } });
            })();
        }
        return () => {
        };
    }, [segmentId]);


    return { loading, error };

};

