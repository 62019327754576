import { Alert, AlertColor, BottomNavigation, LinearProgress, Snackbar, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { SegmentationSummary, SegmentDetails } from '../components';
import { IDimension, ISegmentationItem, ISegmentationSummary } from '../common/interface';
import { useTranslation } from 'react-i18next';
import { useCreateSegment, useDeleteSegment, useGetSegmentsOverview, useUpdateSegments } from '../services/segments';
import { useGetDimensionsValues } from '../services/dimensions';

export default function Segmentation() {

	const { t } = useTranslation();
	const [mode, setMode] = React.useState<'summary' | 'detail'>('summary');
	const [segment, setSegment] = React.useState<ISegmentationItem | null>(null);
	const [dimensions, setDimensions] = React.useState<Array<IDimension>>([]);
	const [summary, setSummary] = React.useState<ISegmentationSummary | null>(null);
	const [exports, setExports] = React.useState<Array<string>>([]);
	const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
	const [snackState, setSnackState] = React.useState<AlertColor>('success');
	const [snackMessage, setSnackMessage] = React.useState<string | null>(null);
	const [changed, setChanged] = React.useState<boolean>(false);
	const [reloadSegments, setReloadSegment] = React.useState<boolean>(true);
	const [executing, setExecuting] = React.useState<boolean>(false);

	const [newSegment, setNewSegment] = React.useState<Partial<ISegmentationItem> | null>(null);
	const [updatedSegment, setUpdatedSegment] = React.useState<Partial<ISegmentationItem> | null>(null);
	const [segmentId, setSegmentId] = React.useState<string | null>(null);
	const [staticSegment, setStaticSegment] = React.useState<boolean | null>(null);
	const [refreshOption, setRefreshOptions] = React.useState<boolean>(false);

	const { loading: loadingColumnOptions, data: columnOptions, error: errorColumnOptions } = useGetDimensionsValues(refreshOption);
	const { loading: loadingOverview, data: overview, error: errorOverview } = useGetSegmentsOverview(reloadSegments);
	const { loading: loadingCreateSegment, error: errorCreateSegment } = useCreateSegment(newSegment);
	const { loading: loadingUpdateSegment, error: errorUpdateSegment } = useUpdateSegments(updatedSegment, changed);
	const { loading: loadingDeleteSegment, error: errorDeleteSegment } = useDeleteSegment(segmentId, staticSegment);

	useEffect(() => {
		setExecuting(loadingColumnOptions || loadingOverview || loadingCreateSegment || loadingUpdateSegment || loadingDeleteSegment);
	}, [loadingColumnOptions, loadingOverview, loadingCreateSegment, loadingUpdateSegment, loadingDeleteSegment]);

	React.useEffect(() => {
		if (loadingOverview) {
			return;
		}
		if(errorOverview){
			setSnackBarState('error', t('userMessages.segmentsLoadError'));
		} else if (overview) {
			setSummary(overview.summary);
			setDimensions(overview.dimensions);
			setExports(overview.exports);
			setReloadSegment(false);
		}
	}, [loadingOverview]);

	React.useEffect(() => {
		if (!loadingDeleteSegment && changed) {
			if(errorDeleteSegment){
				setSnackBarState('error', t('userMessages.segmentDeleteFail'));
			} else {
				setSnackBarState('success', t('userMessages.segmentDeleted'));
				window.location.reload();
			}
			setChanged(false);
		}
	}, [loadingDeleteSegment]);

	React.useEffect(() => {
		if (!loadingUpdateSegment && changed) {
			if(errorUpdateSegment){
				setSnackBarState('error', t('userMessages.segmentUpdateFail'));
			} else {
				setSnackBarState('success', t('userMessages.segmentUpdated'));
				setReloadSegment(true);
			}
			setChanged(false);
		}
	}, [loadingUpdateSegment]);

	React.useEffect(() => {
		if (!loadingCreateSegment && changed) {
			if(errorCreateSegment){
				setSnackBarState('error', t('userMessages.segmentCreateFail'));
			} else {
				setSnackBarState('success', t('userMessages.segmentCreated'));
				setReloadSegment(true);
			}
			setChanged(false);
		}
	}, [loadingCreateSegment]);

	React.useEffect(() => {
		if (!loadingColumnOptions) {
			if (errorColumnOptions) {
				setSnackBarState('error', t('userMessages.optionsLoadError'));
			}
			setRefreshOptions(false);
		}
	}, [loadingColumnOptions]);

	const changeMode = (mode: 'summary' | 'detail') => {
		if (mode === 'detail') {
			if (!columnOptions || (dimensions && dimensions.length !== columnOptions.length)) {
				setRefreshOptions(true);
			}
		}
		setMode(mode);
		setSegment(null);
	}


	const onRowClick = (segment: ISegmentationItem) => {
		setMode('detail');
		setSegment(segment);
	}

	const updateSegment = async (segment: Partial<ISegmentationItem>) => {
		setUpdatedSegment(segment);
		setChanged(true);
	}

	const createSegment = async (segment: Partial<ISegmentationItem>) => {
		setUpdatedSegment(null);
		setNewSegment(segment);
		setChanged(true);
	}

	const removeSegment = async (segmentId: string, staticSegment: boolean) => {
		setSegmentId(segmentId);
		setStaticSegment(staticSegment);
		setChanged(true);
	}

	const setSnackBarState = (severity: 'error' | 'success', message: string) => {
		setOpenSnackbar(true);
		setSnackState(severity);
		setSnackMessage(message)
	}

	return (
		<div>
			{
				executing && <LinearProgress />
			}
			<>
				{mode === 'summary' && overview &&
					<SegmentationSummary
						overview={overview}
						onModeChange={changeMode}
						onRowClick={onRowClick}
						handleRemoveRow={removeSegment}
					/>
				}
				{mode === 'detail' &&
					<SegmentDetails
						onModeChange={changeMode}
						segment={segment}
						dimensions={dimensions}
						exports={exports}
						usersTotal={summary?.total_customers}
						handleUpdate={updateSegment}
						handleCreate={createSegment}
						columnOptions={columnOptions}
					/>
				}
			</>
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={openSnackbar}
				onClose={() => setOpenSnackbar(false)}
				autoHideDuration={6000}
			>
				<Alert severity={snackState}>
					{snackMessage}
				</Alert>
			</Snackbar>
			<BottomNavigation>
				<Typography variant={'subtitle1'} sx={{ color: '#a3a2a2'}}>{t('footer')}</Typography>
			</BottomNavigation>
		</div>
	);
}
