import { AddCircle, Cancel, Delete, Edit } from '@mui/icons-material';
import {
    Alert, Autocomplete,
    Button,
    Card,
    CardActions,
    CardContent,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputLabel, LinearProgress,
    MenuItem, Paper,
    Popover,
    Select, SelectChangeEvent, Snackbar,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableRow,
    TextField,
    Typography, useTheme,
} from '@mui/material';
import * as lodash from 'lodash';
import React, { useEffect } from 'react';
import { getConstrainByDimension } from '../common/utils';
import { EColumnTypes, ECondition, EConstrainType, EOperators } from '../common/enums';
import { IDimensionOption, IDimension, ISegmentConstrain } from '../common/interface';
import FormActionButtons from './form-action-button';
import { useTranslation } from 'react-i18next';
import { useSearchDimensionsValues } from '../services/dimensions';

export default function ConstrainTable(
    props: {
        constrainType: EConstrainType;
        constrains: Array<Partial<ISegmentConstrain>> | null;
        dimensions: Array<IDimension>;
        updateConstrains: Function;
        columnOptions: Array<IDimensionOption>;
        segmentId?: string;
    }
) {

    const { t } = useTranslation();
    const theme = useTheme();
    const [rowConstrains, setRowConstrains] = React.useState<Array<Partial<ISegmentConstrain>>>([]);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | undefined>(undefined);
    const [openDialog, setOpenDialog] = React.useState<boolean>(false);
    const [currentRowIndex, setCurrentRowIndex] = React.useState<number | undefined>(undefined);
    const [currentRowCondition, setCurrentRowCondition] = React.useState<string | undefined>(undefined);
    const [currentRowConditionValue, setCurrentRowConditionValue] = React.useState<string | undefined>(undefined);
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [severity, setSeverity] = React.useState<'error' | 'warning' | 'success'>('success');
    const [message, setMessage] = React.useState<string | null>(null);
    const [showConstrain, setShowConstrain] = React.useState<boolean>(false);
    const [executing, setExecuting] = React.useState<boolean>(false);
    const [changed, setChanged] = React.useState<boolean>(false);
    const [searchColumnOptions, setSearchColumnOptions] = React.useState<Array<IDimensionOption>>([]);

    const [searchInput, setSearchInput] = React.useState<string | undefined>(undefined);
    const [dimensionName, setDimensionName] = React.useState<string | undefined>(undefined);

    const { loading: loadingColumnOptions, data: columnOptions, error: errorColumnOptions } = useSearchDimensionsValues(changed, dimensionName, searchInput);

    useEffect(() => {
        setExecuting(loadingColumnOptions);
    }, [loadingColumnOptions]);


    useEffect(() => {

        setAnchorEl(undefined);
        setCurrentRowIndex(undefined);
        setCurrentRowCondition(undefined);
        setCurrentRowConditionValue(undefined);
        setSearchColumnOptions(props.columnOptions);
    }, [props.constrains]);


    useEffect(() => {
        if (!loadingColumnOptions && errorColumnOptions) {
            if (errorColumnOptions) {
                setSnackBarState('error', t('userMessages.optionsLoadError'));
            } else if (columnOptions) {
                handleOptionsReload(columnOptions.values);
            }
        }
    }, [loadingColumnOptions]);

    React.useEffect(() => {
        let rowConstrains;
        let showConstrain;
        if (props.constrains && props.constrains.length) {
            rowConstrains = deepClone(props.constrains);
            showConstrain = true;
        } else {
            rowConstrains = [{}];
            showConstrain = props.constrainType === EConstrainType.INCLUDE;
        }
        setSearchColumnOptions(deepClone(props.columnOptions));
        setRowConstrains(rowConstrains);
        setShowConstrain(showConstrain);
    }, []);

    React.useEffect(() => {
        if (props.constrains && props.constrains.length) {
            setRowConstrains(deepClone(props.constrains));
        } else {
            setRowConstrains([{}]);
        }
    }, [props.segmentId]);

    const renderTable = () => {
        return (
            <Table
                sx={{
                    [`& .${tableCellClasses.root}`]: {
                        borderBottom: 'none',
                    },
                }}
                size="small"
            >
                <TableBody>
                    {rowConstrains?.map((item: Partial<ISegmentConstrain>, index: number) =>
                        renderRow(item, index),
                    )}
                </TableBody>
            </Table>
        );
    };

    const convertDimensionTypes = (type: string) => {
        switch (type) {
            case 'string':
                return 'text';
            case 'number':
                return type;
            case 'boolean':
                return 'true/false';
            default:
                setSnackBarState('error', t('userMessages.unknownType'));
        }
    };

    const renderRow = (row: Partial<ISegmentConstrain>, index: number) => {
        return (
            <>
                {row.operator &&
                    <TableRow
                        key={`select-dimension-${index}`}
                        style={{ height: '10px', borderBottom: 'none' }}
                    >
                        <TableCell align="center" width="10%">
                            <Divider textAlign="left"><Typography variant={'body2'}>{row.operator}</Typography></Divider>
                        </TableCell>
                        <TableCell align="center" width="10%">
                            <Divider />
                        </TableCell>
                        <TableCell align="center" width="10%">
                            <Divider />
                        </TableCell>
                    </TableRow>
                }
                <TableRow
                    key={'select-dimension'}
                >
                    <TableCell component="th" scope="row" width="30%">
                        <Grid item>
                            <Select
                                id={'access-select'}
                                sx={{ width: 250 }}
                                value={row.dimension?.columnName || ""}
                                displayEmpty
                                size={'small'}
                                renderValue={selected => {
                                    if (!selected && !row.dimension) {
                                        return t('constrainDetail.selectDimension');
                                    }
                                    return selected || row.dimension?.columnName;
                                }}
                                onChange={(event: SelectChangeEvent<string>) => {
                                    handleConstrainDimensionChange(index, getDimensionByName(event.target.value));
                                }}
                            >
                                {props.dimensions?.map((item) => {
                                    return (
                                        <MenuItem value={item.columnName} key={item.columnName} sx={{ justifyContent: 'flex-end' }}>
                                            <Grid item sx={{ display: 'flex', flex: 1, paddingRight: 1 }}>{item.columnName}</Grid>
                                            <Grid item>({convertDimensionTypes(item.columnType)})</Grid>
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </Grid>
                    </TableCell>
                    <TableCell align="left" width="60%">
                        {row?.condition && row.value &&
                            <Chip
                                label={`${t(`conditions.${row.condition}`)} ${row.value}`}
                                variant={'outlined'}
                                deleteIcon={<Edit />}
                                onClick={(event) => {
                                    setAnchorEl(event.currentTarget);
                                    setCurrentRowIndex(index);
                                    setCurrentRowCondition(row.condition);
                                    setCurrentRowConditionValue(row.value?.toString());
                                }}
                            />
                        }
                        {!row?.condition && row.dimension &&
                            <Button startIcon={<AddCircle />} onClick={(event) => {
                                setAnchorEl(event.currentTarget);
                                setCurrentRowIndex(index);
                            }}>
                                {t('button.addFilter')}
                            </Button>
                        }
                    </TableCell>
                    <TableCell align="center" width="10%">
                        <IconButton onClick={() => {
                            setCurrentRowIndex(index);
                            setOpenDialog(true);
                        }}>
                            <Cancel />
                        </IconButton>
                    </TableCell>

                </TableRow>
            </>
        );
    };

    const renderOperatorButtons = () => {
        return (
            <Grid>
                {Object.keys(EOperators).map(operator => {
                    return (
                        <Button
                            variant={'outlined'}
                            onClick={() => handleAddConstrain(operator as EOperators)} sx={{ m: 2 }}
                        >
                            {t(`button.${operator}`)}
                        </Button>
                    );
                })}
            </Grid>
        );
    };

    const renderConditionInputCard = (row: Partial<ISegmentConstrain>) => {
        return (
            <Card>
                {
                    executing && <LinearProgress />
                }
                <CardContent>
                    <Grid item sx={{ margin: 2 }}>
                        <FormControl fullWidth size={'small'}>
                            <InputLabel id="condition-select-label">{t('constrainDetail.condition')}</InputLabel>
                            <Select
                                id={'condition-select'}
                                sx={{ minWidth: 250 }}
                                value={currentRowCondition}
                                defaultValue={''}
                                label={t('constrainDetail.condition')}
                                onChange={(event) => handleConditionSelect(event.target.value)}
                            >
                                {row?.dimension?.columnType && getConstrainByDimension(row.dimension.columnType).map((item, index) => {
                                    return (
                                        <MenuItem value={item} key={`${item}-${index}`}>{t(`conditions.${item}`)}</MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={{ margin: 2 }}>
                        {renderDimensionValue(row)}
                    </Grid>
                </CardContent>
                <CardActions>
                    <Grid container spacing={3} justifyContent={'flex-end'}>
                        <Grid item>
                            <Button variant={'text'} onClick={() => handlePopoverClose()}>
                                {t('button.cancel')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant={'text'}
                                onClick={() => handlePopoverSave(row)}
                                disabled={!currentRowCondition || !currentRowConditionValue}
                            >
                                {t('button.apply')}
                            </Button>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        );
    };

    const renderDimensionValue = (row: Partial<ISegmentConstrain>) => {
        if (row?.dimension && (row.dimension.columnType === 'string' || row.dimension.columnType === 'boolean')) {
            return (<Autocomplete
                id={`${props.segmentId}-${row.dimension.columnName}`}
                size={'small'}
                freeSolo
                disableListWrap
                options={searchColumnOptions?.find(column => column.columnName === row.dimension?.columnName)?.values || []}
                value={row.value}
                getOptionLabel={(option) => `${option}`}
                renderInput={(params) =>
                    <TextField
                        sx={{
                            '& .MuiFormHelperText-root': {
                                width: 300,
                            },
                        }}
                        label={t('constrainDetail.value')}
                        helperText={t('constrainDetail.searchColumnValue')}
                        {...params}
                    />}
                onChange={(event, value: string | number | boolean | null, reason) => handleSearchConditionValueInput(value)}
                onInputChange={async (event, value, reason) =>
                    row.dimension?.columnName && await handleInputChange(row.dimension.columnName, value, reason)}
            />);
        }
        return (
            <TextField
                fullWidth
                size={'small'}
                value={currentRowConditionValue}
                label={t('constrainDetail.value')}
                onChange={(event) => handleConditionValueInput(event.target.value)}
            />
        );
    };

    const handlePopoverClose = () => {
        setAnchorEl(undefined);
        setCurrentRowIndex(undefined);
        setCurrentRowCondition(undefined);
        setCurrentRowConditionValue(undefined);
        setSearchColumnOptions(props.columnOptions);
    };


    const handleConditionSelect = (condition: string) => {
        setCurrentRowCondition(condition);
    };

    const handleSearchConditionValueInput = (value: number | string | boolean | null) => {
        value && setCurrentRowConditionValue(value.toString());
    };

    const handleConditionValueInput = (value: number | string | boolean) => {
        value && setCurrentRowConditionValue(value.toString());
    };

    const handleInputChange = async (columnName: string, value: string, reason: string) => {
        value && setCurrentRowConditionValue(value.toString());
        try {
            if (!value || reason === 'clear') {
                retrieveOptions(columnName);
            } else if (reason === 'input' && value.length > 2) {
                await updateOptions(columnName, value);
            }
        } catch (error) {
            setSnackBarState('error', t('userMessages.optionsLoadError'));
        }
    };

    const retrieveOptions = (columnName: string) => {
        const column = props.columnOptions.find(item => item.columnName === columnName);
        if (column) {
            handleOptionsReload(column.values, columnName);
        }
    };

    const updateOptions = async (columnName: string, value: string) => {
        setDimensionName(columnName);
        setSearchInput(value);
        setChanged(true);
    };

    const handleOptionsReload = (values: Array<string>, columnName?: string) => {
        if (!columnName) {
            if (!dimensionName) {
                setSnackBarState('error', t('userMessages.optionsLoadError'));
                return;
            }
            columnName = dimensionName;
        }
        if (!searchColumnOptions) {
            setSearchColumnOptions([{ columnName, values }]);
            return;
        }
        const columnOptions = deepClone(searchColumnOptions);
        const currentColumnOptions = columnOptions.find((column: IDimensionOption) => column.columnName === columnName);
        if (!currentColumnOptions) {
            columnOptions.push({ columnName, values });
        } else {
            currentColumnOptions.values = values;
        }
        setSearchColumnOptions(columnOptions);
    };

    const handlePopoverSave = (row: Partial<ISegmentConstrain>) => {
        let value;
        const constrains = rowConstrains;

        const newDimension = row.dimension;
        if (newDimension?.columnType === EColumnTypes.NUMBER) {
            if (typeof currentRowConditionValue === 'string') {
                value = parseInt(currentRowConditionValue, 10);
            }
            if (value !== undefined && isNaN(value)) {
                setSnackBarState('error', t('userMessages.invalidConstrainInput'));
                return;
            }
        } else if (newDimension?.columnType === EColumnTypes.BOOLEAN) {
            value = currentRowConditionValue && currentRowConditionValue.toLowerCase();
            if (value !== 'true' && value !== 'false') {
                setSnackBarState('error', t('userMessages.invalidConstrainInput'));
                return;
            }
        }
        if (currentRowCondition === ECondition.REGEXP && currentRowConditionValue) {
            try {
                new RegExp(currentRowConditionValue);
            } catch (e) {
                setSnackBarState('error', t('userMessages.invalidRegex'));
            }
            return;
        }

        if (currentRowIndex !== undefined) {
            constrains[currentRowIndex].value = value || currentRowConditionValue;
            constrains[currentRowIndex].condition = currentRowCondition;
        }
        setRowConstrains(constrains);
        setCurrentRowCondition(undefined);
        setCurrentRowConditionValue(undefined);
        setCurrentRowIndex(undefined);
        setAnchorEl(undefined);
        setSearchColumnOptions(props.columnOptions);

        setSnackBarState('success', t('userMessages.constrainInputSuccess'));
        props.updateConstrains(constrains, true);
    };

    const handleConstrainDimensionChange = (index: number, newDimension: IDimension) => {
        const constrains = rowConstrains;
        constrains[index].dimension = {
            columnType: newDimension.columnType,
            columnName: newDimension.columnName,
        };
        constrains[index].condition = undefined;
        constrains[index].value = undefined;

        setRowConstrains(constrains);
        props.updateConstrains(constrains);
    };

    const handleAddConstrain = (operator: EOperators) => {
        const constrains = rowConstrains;
        constrains.push({ operator });
        setRowConstrains(constrains);
        props.updateConstrains(constrains);
    };


    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const getDimensionByName = (name: string): IDimension => {
        return props.dimensions?.find(item => item.columnName === name) as IDimension;
    };

    const handleRemoveConstrain = (index: number) => {
        if (rowConstrains.length === 1) {
            props.updateConstrains(null);
            return;
        }
        if (index !== rowConstrains.length - 1) {
            rowConstrains[index + 1].operator = undefined;
        }
        rowConstrains.splice(index, 1);
        props.updateConstrains(rowConstrains);
    };

    const setSnackBarState = (severity: 'error' | 'warning' | 'success', message: string) => {
        setSeverity(severity);
        setMessage(message);
        setShowSnackbar(true);
    };
    const renderConstrain = () => {
        return (
            <Paper
                sx={{
                    padding: 2,
                    backgroundColor: '#c5c5c5',
                    borderStyle: 'none none none solid',
                    borderWidth: '5px',
                    borderColor: props.constrainType === EConstrainType.INCLUDE
                        ? theme.palette.success.main
                        : theme.palette.error.main,
                }}
            >
                <Grid container justifyContent={'space-between'}>
                    <Grid item>
                        <Typography variant={'subtitle1'} sx={{ padding: 1, fontWeight: 'bold' }}>{
                            t(props.constrainType === EConstrainType.INCLUDE
                                ? 'constrainDetail.inclusionConstrain'
                                : 'constrainDetail.exclusionConstrain',
                            )
                        }</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={() => {
                            setShowConstrain(false);
                            props.updateConstrains(null);
                        }}>
                            <Delete />
                        </IconButton>
                    </Grid>
                </Grid>
                {renderConstrainCard()}
            </Paper>
        );
    };

    const renderConstrainCard = () => {
        return (
            <>
                <Card>
                    <CardContent>
                        {renderTable()}
                    </CardContent>
                    <CardActions>
                        {renderOperatorButtons()}
                    </CardActions>
                </Card>
            </>
        );
    };

    const renderAddConstrainButton = () => {
        return (
            <Grid item sx={{ display: 'flex', flex: 1 }}>
                <Button startIcon={<AddCircle />} onClick={() => {
                    setShowConstrain(true);
                }}>
                    {t(props.constrainType === EConstrainType.INCLUDE
                        ? 'constrainDetail.addInclusion'
                        : 'constrainDetail.addExclusion',
                    )}
                </Button>
            </Grid>
        );
    };

    const deepClone = (value: any) => {
        return lodash.cloneDeep(value);
    };

    return (
        <>
            <Grid item sx={{ my: 3 }}>
                {showConstrain
                    ? renderConstrain()
                    : renderAddConstrainButton()
                }
            </Grid>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl || null}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {currentRowIndex != null && renderConditionInputCard(rowConstrains[currentRowIndex])}
            </Popover>
            <Dialog open={openDialog} onClose={handleDialogClose} maxWidth="md" fullWidth>
                <DialogTitle>
                    {t('constrainDetail.condition')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('userMessages.deleteConstrainConfirm')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ margin: 10 }}>
                    <FormActionButtons
                        onConfirm={() => {
                            currentRowIndex && handleRemoveConstrain(currentRowIndex);
                            handleDialogClose();
                        }}
                        onClose={() => handleDialogClose()}
                        confirmText={t('button.confirm')}
                        closeText={t('button.cancel')}
                    />
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={showSnackbar}
                onClose={() => {
                    setShowSnackbar(false);
                }}
                autoHideDuration={6000}
            >
                <Alert severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    );


}
