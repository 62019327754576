import axios, { AxiosInstance, AxiosRequestConfig, CreateAxiosDefaults } from 'axios';
import React from 'react';
import { environment } from '../environments/environment';

class AxiosClient {
	private _client:  AxiosInstance;

	constructor(config?: CreateAxiosDefaults<any>) {
		this._client = axios.create(config);
	}

	public async get(
		url: string,
		setData: React.Dispatch<React.SetStateAction<any>>,
		setError: React.Dispatch<React.SetStateAction<Error | null>>,
		setLoading: React.Dispatch<React.SetStateAction<boolean>>,
		config?: AxiosRequestConfig<any>
	) {
		try {
			const res = await this._client.get(url, config);
			setData(res.data);
		} catch (err) {
			// @ts-ignore
			setError(err);
		} finally {
			setLoading(false);
		}
	}


	public async post(
		url: string,
		setData: React.Dispatch<React.SetStateAction<any>>,
		setError: React.Dispatch<React.SetStateAction<Error | null>>,
		setLoading: React.Dispatch<React.SetStateAction<boolean>>,
		config?: AxiosRequestConfig<any>,
		data?: Record<string, any>,
	) {
		try {
			const res = await this._client.post(url, data, config);
			setData(res.data);
		} catch (err) {
			// @ts-ignore
			setError(err);
		} finally {
			setLoading(false);
		}
	}


	public async put(
		url: string,
		setError: React.Dispatch<React.SetStateAction<Error | null>>,
		setLoading: React.Dispatch<React.SetStateAction<boolean>>,
		setData?: React.Dispatch<React.SetStateAction<any>>,
		config?: AxiosRequestConfig<any>,
		data?: Record<string, any>,
	) {
		try {
			const res = await this._client.put(url, data, config);
			if (setData) {
				setData(res.data);
			}
		} catch (err) {
			// @ts-ignore
			setError(err);
		} finally {
			setLoading(false);
		}
	}


	public async delete(
		url: string,
		setError: React.Dispatch<React.SetStateAction<Error | null>>,
		setLoading: React.Dispatch<React.SetStateAction<boolean>>,
		setData?: React.Dispatch<React.SetStateAction<any>>,
		config?: AxiosRequestConfig<any>
	) {
		try {
			const res = await this._client.delete(url, config);
			if (setData) {
				setData(res.data);
			}
		} catch (err) {
			// @ts-ignore
			setError(err);
		} finally {
			setLoading(false);
		}
	}
}

export default new AxiosClient({
	baseURL: environment.backendEndpoint,
	headers: {'Content-Type': 'application/json', 'x-api-key' : `${environment.apiKey}`}
});
