import React from 'react';
import AxiosClient from './axiosClient';
import { IDimensionOption } from '../common/interface';

const urlPrefix = '/api/dimensions';

export const useGetDimensionsValues = (refresh: boolean) => {
    const [data, setData] = React.useState<Array<IDimensionOption>>([]);
    const [error, setError] = React.useState<Error | null>(null);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);
        (async () => {
            await AxiosClient.get(`${urlPrefix}/values`, setData, setError, setLoading);
        })();
        return () => {
        };
    }, [refresh]);


    return { loading, data, error };

};

export const useSearchDimensionsValues = (changed: boolean, column: string | undefined, input: string | undefined) => {
    const [data, setData] = React.useState<IDimensionOption | null>(null);
    const [error, setError] = React.useState<Error | null>(null);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (changed && column && input) {
            setLoading(true);
            (async () => {
                await AxiosClient.get(`${urlPrefix}/search`, setData, setError, setLoading, { params: { columnName: column, searchInput: input }} );
            })();
        }
        return () => {
        };
    }, [column, input]);


    return { loading, data, error };

};
