import React from 'react';
import './App.css';
import Segmentation from './pages/segmentation';
import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import { colors } from '@mui/material';

const defaultTheme: ThemeOptions = createTheme({
    palette: {
        mode: 'light',
        error: colors.red,
        success: colors.green,
        warning: colors.amber,
        primary: {
            light: '#575578',
            main: '#2d2c4c',
            dark: '#1f1d36',
            contrastText: '#ffffff',
        },
        secondary: {
            light: '#ffff52',
            main: '#ffd700',
            dark: '#c7a600',
            contrastText: '#000000'
        },
    },
    components: {
        MuiGrid: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent',
                },
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    padding: 0,
                    backgroundColor: 'transparent',
                },
            },
        },
    },
});

function App() {
    return (
        <ThemeProvider theme={defaultTheme}>
            <div className='App'>
                <Segmentation />
            </div>
        </ThemeProvider>

    );
}

export default App;
